import React, { useState, useCallback } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Link } from './link'
import VideoModal from './video-modal'
import ChevronRightIcon from '../images/icons/material/chevron_right.svg'
import PlayArrowIcon from '../images/icons/material/play_arrow.svg'


const TwoColumns = ({ data: { image }, className }) => {
  const [hover, setHover] = useState(false);
  const onHover = useCallback(() => setHover(true), []);
  const onOut = useCallback(() => setHover(false), []);
  const videoLink = image && image.content && image.content.videoLink && image.content.videoLink.value;
  const [videoVisible, setVideoVisible] = useState(false);
  const showVideo = useCallback(() => {
    if (videoLink) {
      setVideoVisible(true);
    }
  }, [videoLink]);
  const closeVideo = useCallback(() => setVideoVisible(false), []);
  if (!image) return null;

  return (
    <div className={`section two-columns${hover ? ' hover' : ''}${className ? ` ${className}` : ''}`}>
      <div className="container">
        <div className={`row mb-4 pb-4`}>
          <div className={`col-12 mb-4 mb-lg-0 col-lg-6 ${image.content.imageLeft ? 'order-lg-2 pl-xl-7' : 'order-lg-1 pr-xl-7 '}`}>
            <div className="d-flex flex-column h-100  justify-content-center">
              {image.content.icon ? <i className={`nj-icon size-126 nj-icon-${image.content.icon.value} nj-icon--blue-corporate`}></i> : null}
              {image.content.mainTitle ? <h2>{image.content.mainTitle.value}</h2> : null}
              <div className="two-columns__text-container mb-4">
                {image.content.mainSubtitle ? <p className="text-blue-corporate font-weight-bold two-columns__subtitle">{image.content.mainSubtitle.value}</p> : null}
                {image.content.mainDescription && image.content.mainDescription.value ? (
                  <div className="two-coumns__text mt-5" dangerouslySetInnerHTML={{ __html: image.content.mainDescription.value }}></div>
                ): null}
              </div>
              <div>
                {image.content.btnText && image.content.btnText.content && image.content.btnText.content.btnLink && image.content.btnText.content.btnLink.value ? (
                  <Link to={image.content.btnText.content.btnLink.value} 
                  className={`nj-btn align-self-start ${image.content.btnText.content.variantWhite ? 'nj-btn--outline-primary': 'nj-btn--primary'} `}
                  onMouseOver={onHover} onFocus={onHover} onMouseOut={onOut} onBlur={onOut}>
                    {image.content.btnText.value}
                  </Link>
                ): null}
                {image.content.cta && image.content.cta.value && image.content.cta.content.ctaLink &&
                  <Link to={image.content.cta.content.ctaLink} className="nj-link nj-link-icon ml-4">
                    <span>{image.content.cta.value}</span>
                    <ChevronRightIcon className="material-svg" />
                  </Link>
                }
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 px-0 px-sm-2">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div className={`two-columns__img${videoLink ? ' two-columns__img--video': ''}`} onClick={showVideo}>
              <Img image={getImage(image.node)} alt={image.node.description || ''} />
              {videoLink ? (
                <div className="two-columns__play bg-blue-corporate">
                  <PlayArrowIcon className="material-svg text-white" />
                </div>
              ): null}
            </div>
          </div>
        </div>
      </div>
      <VideoModal show={videoVisible} handleClose={closeVideo} video={videoLink} />
    </div>
  );
}

export default TwoColumns;
export const twoColumnsFragment = graphql`fragment ProdottoinformativoFragment on LiferayProdottoinformativo{
  siteId
  articleId
  image {
    node {
      description
      gatsbyImageData(width: 688)
    }
    content {
      icon {
        value
      }
      imageLeft
      mainTitle {
        value
      }
      mainSubtitle {
        value
      }
      mainDescription {
        value
      }
      videoLink {
        value
      }
      btnText {
        value
        content {
          btnLink {
            value
          }
          variantWhite
        }
      }
    }
  }
}`;
